
import {tap} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {AuthService} from '../../modules/authentication/services/auth.service';

@Injectable()
export class UnauthorizedService implements HttpInterceptor {
  private auth: AuthService;

  constructor(injector: Injector) {
    setTimeout(() => this.auth = injector.get(AuthService));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => {
    }, err => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.auth.logout();
      }
    }));
  }

}
