import {Component} from '@angular/core';

import {AuthService} from './modules/authentication/services/auth.service';


@Component({
  selector: 'body',
  template: `
    <div>
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(public auth: AuthService) {
  }

}
