import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import * as io from 'socket.io-client';

import {environment} from '../../../environments/environment';


@Injectable()
export class WebSocketService {

  private socket: io.Socket;

  constructor() {
    this.socket = io(environment.socket.baseUrl, environment.socket.opts);
  }

  // Emit: user logged in event
  emitEventOnUserLoggedIn(userEmail) {
    this.socket.emit('userLoggedIn', userEmail);
  }

  // Emit: project created event
  emitEventOnProjectCreated(projectName) {
    this.socket.emit('projectCreated', projectName);
  }

  // Emit: project budgeted event
  emitEventOnProjectBudgeted(projectName) {
    this.socket.emit('projectBudgeted', projectName);
  }

  // Emit: project validated event
  emitEventOnProjectValidated(projectName) {
    this.socket.emit('projectValidated', projectName);
  }

  // Emit: zoho project created event
  emitEventOnZohoProjectCreated(projectName) {
    this.socket.emit('zohoProjectCreated', projectName);
  }

  consumeEventOnWelcomeUserLoggedIn() {
    return Observable.create(observer => {
      this.socket.on('welcomeUserLoggedIn', (message) => {
        observer.next({message: {type: 'info', title: 'User Logged In', body: message}});
      });
    });
  }

  consumeEventOnProjectCreated() {
    return Observable.create(observer => {
      this.socket.on('projectCreated', (response) => {
        observer.next({message: {type: 'info', title: 'Project Creation', body: response.message}, data: response.notif});
      });
    });
  }

  consumeEventOnProjectBudgeted() {
    return Observable.create(observer => {
      this.socket.on('projectBudgeted', (response) => {
        observer.next({
          message: {type: 'info', title: 'Project Budgeted', body: response.message},
          data: response.notif
        });
      });
    });
  }

  consumeEventOnProjectValidated() {
    return Observable.create(observer => {
      this.socket.on('projectValidated', (response) => {
        observer.next({
          message: {type: 'info', title: 'Project Validated', body: response.message},
          data: response.notif
        });
      });
    });
  }

  consumeEventOnZohoProjectCreated() {
    return Observable.create(observer => {
      this.socket.on('zohoProjectCreated', (response) => {
        observer.next({
          message: {type: 'info', title: 'Project Created on Zoho', body: response.message},
          data: response.notif
        });
      });
    });
  }

}
