import {Component, OnInit} from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup,AbstractControl, Validators} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import {AuthService} from '../services/auth.service';
import {WebSocketService} from '../../../shared/services/web-socket.service';
import {snackBarOptions} from '../../../shared/configuration/snackbar';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token:string;
  isValid:boolean;
  message: string = '';
  actionButtonLabel: string = '';
  constructor(private auth: AuthService,
              private formBuilder: FormBuilder,
              private router: Router,
              private route:ActivatedRoute,
              private webSocketService: WebSocketService,
              private _snackBar: MatSnackBar) {
  }

  ngOnInit() {
    if (this.auth.loggedIn) {
      this.router.navigate(['/projects']);
    }
    this.route.params.subscribe( (params) =>
      this.token = params['token']
    )
    this.isTokenValid(this.token);
    const passwordControlConfig = {
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required]
    };
    this.resetPasswordForm = this.formBuilder.group(passwordControlConfig, {
      validator: this.MatchPassword
    });
  }


  private isTokenValid(token) {
    this.auth.getResetPasswordForm(token).subscribe(
      res => {
        if(res.status ==="ok"){
          this.isValid =true;
        }else{
          this.isValid =false;
        }
      },
      error =>{
        this.message = 'invalid email or password!';
        this.actionButtonLabel = 'Dismiss';
        this.openSnackBar();
      }
    );
  }
  private resetPassword(){
    const data = this.resetPasswordForm.value;
    const token:string="";
    this.route.params.subscribe( (params) =>
      this.token = params['token']
    )
    this.auth.resetPassword(data,this.token).subscribe(
      res => {
        this.message = 'profile updated succesfully';
        this.actionButtonLabel = 'Dismiss';
        this.openSnackBar();
        localStorage.setItem('token', res.token);
        const decodedUser = this.auth.decodeUserFromToken(res.token);
        this.auth.setCurrentUser(decodedUser);
        this.router.navigate(['/projects']);
      },
      err => {},
      () => {}
    );
  }
  private MatchPassword(AC: AbstractControl) {
    if (AC.get("password").value !== AC.get("confirmPassword").value) {
      AC.get("confirmPassword").setErrors({ MatchPassword: true });
    } else {
      AC.get("confirmPassword").setErrors(null);
    }
  }
  openSnackBar() {
    let config = new MatSnackBarConfig();
    config.verticalPosition = <MatSnackBarVerticalPosition>snackBarOptions.verticalPosition;
    config.horizontalPosition = <MatSnackBarHorizontalPosition>snackBarOptions.horizontalPosition;
    config.duration = snackBarOptions.setAutoHide ? snackBarOptions.autoHide : 0;
    config.panelClass = snackBarOptions.panelClass;
    this._snackBar.open(this.message, snackBarOptions.action ? snackBarOptions.actionButtonLabel : undefined, config);
  }
}
