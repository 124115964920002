export const snackBarOptions = {
  message: "default message custimize this",
  actionButtonLabel: "Dismiss",
  action: true,
  setAutoHide: true,
  autoHide: 3000,
  horizontalPosition: "center",
  verticalPosition: "top",
  panelClass: "bluetick"
};
