import { map } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { UserService } from "../../administration/users/user.service";

@Injectable()
export class AuthService {
  loggedIn = false;
  isAdmin = false;
  jwtHelper: any = new JwtHelperService();
  currentUser: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    subsidiary: string;
  };
  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  constructor(
    private userService: UserService,
    private router: Router,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedUser = this.decodeUserFromToken(token);
      this.setCurrentUser(decodedUser);
    }
  }

  login(emailAndPassword) {
    return this.userService.login(emailAndPassword).pipe(
      map(res => {
        localStorage.setItem("token", res.token);
        const decodedUser = this.decodeUserFromToken(res.token);
        this.setCurrentUser(decodedUser);
        this.isLoginSubject.next(true);
        return this.loggedIn;
      })
    );
  }

  logout() {
    localStorage.removeItem("token");
    this.cookieService.deleteAll();
    this.loggedIn = false;
    this.isAdmin = false;
    this.currentUser = {
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      subsidiary: "",
      role: ""
    };
    this.isLoginSubject.next(false);
    this.router.navigate(["/login"]);
  }

  decodeUserFromToken(token) {
    return this.jwtHelper.decodeToken(token).user;
  }

  setCurrentUser(decodedUser) {
    this.loggedIn = true;
    this.currentUser = decodedUser;
    this.isAdmin = this.currentUser.role === "admin";
  }
  getCurrentUser() {
      const token = localStorage.getItem('token');
      if(token){
        const decodedUser = this.decodeUserFromToken(token);
        return decodedUser;
      }
  }

  changePassword(data: {
    password: string;
    newPassword: string;
  }): Observable<any> {
    return this.http.post("/api/changePassword", data);
  }

  updateProfile(data): Observable<any> {
    return this.http.put("/api/userprofile", data);
  }

  getProfile(): Observable<any> {
    return this.http.get("/api/userprofile");
  }
  generateresetPasswordForm(data: {
    email: string;
    forgotten: "password";
  }): Observable<any> {
    return this.http.post("/api/resetpassword/generate", data);
  }
  getResetPasswordForm(token): Observable<any> {
    return this.http.get("/api/reset/" + token);
  }
  resetPassword(data, token): Observable<any> {
    return this.http.post("/api/reset/" + token, data);
  }
   /**
     * if we have token the user is loggedIn
     * @returns {boolean}
     */
   hasToken() : boolean {
    return !!localStorage.getItem('token');
  }
}
