import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable()
export class UserService {
  private headers = new HttpHeaders().set(
    "Content-Type",
    "application/json; charset=utf-8"
  );
  private options = { headers: this.headers };

  constructor(private http: HttpClient) {}

  register(user): Observable<any> {
    return this.http.post("/api/users", JSON.stringify(user), this.options);
  }

  login(credentials): Observable<any> {
    credentials.email = credentials.email.toLowerCase();
    return this.http.post(
      "/api/login",
      JSON.stringify(credentials),
      this.options
    );
  }

  getAll(data?: {admin?: boolean}): Observable<any> {
    let options = {};    
    if (data) {
      const httpParams = new HttpParams().set("admin", data.admin.toString());
      options = { params: httpParams };
    }
    return this.http.get("/api/users", options);
  }
  getOne(id): Observable<any> {
    return this.http.get("/api/users/"+id);
  }
  updateUser(id,data): Observable<any> {
    return this.http.put("/api/users/"+id,data);
  }
  deleteUser(id): Observable<any> {
    return this.http.delete("/api/users/"+id, this.options);
  }
  changeStatus(user): Observable<any> {
    return this.http.put(`/api/users/${user._id}/changeStatus`, JSON.stringify(user), this.options);
  }

  uploadProfileAvatar(user, files): Observable<any> {
    return this.http.post(`/api/users/${user._id}/avatar`, files, user);
  }

  getAvatar(user): Observable<any> {
    return this.http.get(`/api/users/${user._id}/avatar`, this.options);
  }
}
