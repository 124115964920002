import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import {AuthService} from '../services/auth.service';
import {snackBarOptions} from '../../../shared/configuration/snackbar';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  resetForm: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(100)]);
  show:boolean = true;
  resetValid:boolean =true;
  message: string = '';
  actionButtonLabel: string = '';
  constructor(private auth: AuthService,
              private formBuilder: FormBuilder,
              private router: Router,
              private _snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: this.email
    });
  }

  setClassEmail() {
    return {'has-danger': !this.email.pristine && !this.email.valid};
  }

  generateResetForm() {
    this.auth.generateresetPasswordForm(this.resetForm.value).subscribe(
      res => {
        console.log(res);
        if(res.status ==="ok"){
        this.message = 'An email has been sent to your inbox with a reset link';
        this.actionButtonLabel = 'Dismiss';
        this.openSnackBar();
        }
        else if (res.status ==="ko"){
          this.show=false;
        }
      },
      error => {
        this.router.navigate(['/forgot']);
      }
    );
  }
  refresh(): void {
    this.show=true;
  }
  openSnackBar() {
    let config = new MatSnackBarConfig();
    config.verticalPosition = <MatSnackBarVerticalPosition>snackBarOptions.verticalPosition;
    config.horizontalPosition = <MatSnackBarHorizontalPosition>snackBarOptions.horizontalPosition;
    config.duration = snackBarOptions.setAutoHide ? snackBarOptions.autoHide : 0;
    config.panelClass = snackBarOptions.panelClass;
    this._snackBar.open(this.message, snackBarOptions.action ? snackBarOptions.actionButtonLabel : undefined, config);
  }
}
