import {BrowserModule} from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie-service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';import {RoutingModule} from './routing.module';
import {UserService} from './modules/administration/users/user.service';
import {AuthService} from './modules/authentication/services/auth.service';
import {AuthGuardLogin} from './modules/authentication/services/auth-guard-login.service';
import {AppComponent} from './app.component';
import {ForgotComponent} from './modules/authentication/forgot/forgot.component';
import {ResetComponent} from './modules/authentication/reset/reset.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {UnauthorizedService} from './shared/services/unauthorized.service';
import {WebSocketService} from './shared/services/web-socket.service';
import {
	MatSnackBarModule
} from '@angular/material';
@NgModule({
  declarations: [
    AppComponent,
    ForgotComponent,
    ResetComponent,
    NotFoundComponent,
  ],
  imports: [
    HttpClientModule,
    RoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule
  ],
  exports: [],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedService, multi: true},
    AuthService,
    UserService,
    CookieService,
    WebSocketService,
    AuthGuardLogin

  ],
  schemas: [],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor() {
    // Consume user events
    // this.webSocketService.consumeEventOnWelcomeUserLoggedIn();
    // this.webSocketService.consumeEventOnNotifyUserLoggedIn();

  }

}
