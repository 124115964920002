<!-- <app-toast [message]="toast.message"></app-toast> -->
<div class="page-login">
  <div class="page vertical-align text-center">
    <div class="page-content vertical-align-middle">
      <div class="brand">
          <a [routerLink]="['/']" class="no-underline">
            <img src="../../assets/img/logo.svg" class="img-logo" style="width: 170px;">
          </a>
      </div>
      <p class="brand-text">Reset Password</p>
      <form *ngIf="isValid; else invalidToken">
          <form class="form-horizontal" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" novalidate>
              <div class="row row-lg">
                <div class="col-lg-6 col-lg-push-3 form-horizontal">
                  <div class="form-group">
                    <label class="col-lg-12 col-sm-3 control-label" for="password">
                      New password
                    </label>
                    <div class="col-lg-12 col-sm-9">
                      <input type="password" id="password" class="form-control" name="password" formControlName="password"/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-lg-12 col-sm-3 control-label" for="confirmPassword">
                      Confirm password
                    </label>
                    <div class="col-lg-12 col-sm-9">
                      <input type="password" id="confirmPassword" class="form-control" name="confirmPassword" formControlName="confirmPassword"/>
                      <small *ngIf="passwordForm?.controls?.confirmPassword?.errors?.MatchPassword"
                             class="help-block">Password not match</small>
                    </div>
                  </div>
                  <button class="btn btn-primary" [disabled]="!resetPasswordForm.valid">
                    Change password
                  </button>
                </div>
              </div>
            </form>
      </form>
      <ng-template  #invalidToken >
          <div class="invalid-token">
            the token is invalid or expired please consider resending a new reset demand.
            <p class="brand-text"><a [routerLink]="['/forgot']" class="no-underline">Reset Password</a></p>
          </div>
      </ng-template>
    </div>
  </div>
</div>
