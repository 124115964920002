<!-- <app-toast [message]="toast.message"></app-toast> -->
<div class="page-login">
  <div class="page vertical-align text-center">
    <div class="page-content vertical-align-middle">
      <div class="brand">
          <a [routerLink]="['/']" class="no-underline">
            <img src="../../assets/img/logo.svg" class="img-logo" style="width: 170px;">
          </a>
      </div>
      <p class="brand-text">Reset Password</p>
      <form *ngIf='show' [formGroup]="resetForm" (ngSubmit)="generateResetForm()">
        <div class="form-group form-material floating" [ngClass]="setClassEmail()">
          <input id="email" class="form-control" type="email" name="email" formControlName="email" autofocus>
          <label class="floating-label" for="email">Email</label>
        </div>
        <button class="btn btn-primary btn-block" [disabled]="!resetForm.valid">Reset password</button>
        <p class="brand-text"><a [routerLink]="['/projects']" class="brand-text no-underline">Cancel</a></p>
      </form>
      <div *ngIf='!show' class="verify-email">
         please ensure that you have an account or enter a valid email
         <p class="brand-text"><a (click)="refresh()" class="no-underline">Reset Password</a></p>
      </div>
    </div>
  </div>
</div>
