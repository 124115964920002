import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ResetComponent} from './modules/authentication/reset/reset.component';
import {ForgotComponent} from './modules/authentication/forgot/forgot.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {AuthGuardLogin} from './modules/authentication/services/auth-guard-login.service';

const routes: Routes = [
    {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: './modules/dashboard.module#DashboardModule',
    canActivate: [AuthGuardLogin],

  },
  {
    path: 'login',
    loadChildren: './modules/authentication/login/login.module#LoginModule'
  },
  {
    path: 'forgot',
    component: ForgotComponent
  },
  {
    path: 'reset/:token',
    component: ResetComponent
  },
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}
