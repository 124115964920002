import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';
import { BehaviorSubject } from "rxjs/BehaviorSubject";

@Injectable()
export class AuthGuardLogin implements CanActivate {
  isLoginSubject = new BehaviorSubject<boolean>(this.hasToken());
  constructor(public auth: AuthService, private router: Router) {
  }

  canActivate() {
    if(!this.hasToken()) {
      this.router.navigate(['login']); // choose either default route like here
      return false;
    }
    return true;

  }
  /**
     * if we have token the user is loggedIn
     * @returns {boolean}
     */
    private hasToken() : boolean {
      return !!localStorage.getItem('token');
    }
}
